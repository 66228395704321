import { Box, Button, Paper, Typography } from '@material-ui/core';
import { ActiveSurveyInstance, type OneOffSurveyInstance, SurveyType } from 'types/Offer';
import {
  formatSurveyDueDateFromDate,
  getSurveyDaysLeftFromDate,
} from 'utils/dateHelpers';

import { useStyles } from 'components/offers/SurveyCard/SurveyCard.styles';
import { SurveyImage } from 'components/offers/SurveyImage';

interface OneOffSurveyProps {
  oneOffSurvey: OneOffSurveyInstance;
  onPress: (data: ActiveSurveyInstance) => void;
}

export const OneOffSurveyCard = (props: OneOffSurveyProps): JSX.Element => {
  const classes = useStyles();
  const { oneOffSurvey, onPress } = props;
  const { id, name, linkUrl, deadline, isOverdue, surveyId, customData } = oneOffSurvey;

  const daysLeft = getSurveyDaysLeftFromDate({ deadline });

  const getBadgeText = () => {
    if (isOverdue) {
      return 'Overdue!';
    } else if (daysLeft <= 1) {
      return 'Complete \nASAP!';
    } else return 'Days Left!';
  };

  const handlePressButton = (): void => {
    onPress({
      surveyUrl: linkUrl ?? '',
      id,
      surveyType: SurveyType.OneOff,
      surveyId,
      customData,
    });
  };
  return (
    <Paper elevation={3} className={classes.container}>
      <SurveyImage
        surveyType={SurveyType.OneOff}
        alt={`OneOff survey: ${name}`}
        onClick={handlePressButton}
        badge={{ value: daysLeft, text: getBadgeText() }}
      />

      <Typography title={name} variant="h6" className={classes.surveyName}>
        {name}
      </Typography>

      <Box className={classes.buttonWrapper}>
        <Typography variant="subtitle1" className={classes.dueDate}>
          {isOverdue ? (
            'Overdue, Complete ASAP!'
          ) : (
            <>
              <span className={classes.boldDueText}>Due: </span>
              {formatSurveyDueDateFromDate({ deadline })}
            </>
          )}
        </Typography>
        <Button onClick={handlePressButton} variant="contained" color="primary">
          Complete
        </Button>
      </Box>
    </Paper>
  );
};
