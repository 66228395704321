export interface OptInSurvey {
  id: string;
  name?: string;
  surveyUrl?: string;
}

export interface ActiveSurveyInstance {
  surveyUrl: string;
  id: string;
  surveyType: SurveyType;
  surveyId: string;
  customData?: Record<string, string>;
}

export interface ItemSurvey extends OptInSurvey {
  item?: SurveyItem;
}

export interface DispatchedOptInSurvey {
  id: string;
  customerId: string;
  status: SurveyStatus;
  // !INFO: startedAt is coming in as seconds instead of miliseconds like a date getTime()
  startedAt?: number; // to check the date: new Date(startedAt * 1000)
  duration: number; // duration is time in seconds ex: 864000 = 10 days
  survey?: OptInSurvey;
}

export interface OptInSurveyInstance extends Survey {
  type: SurveyType.OptIn;
}

export interface OneOffSurveyInstance extends Survey {
  type: SurveyType.OneOff;
}

export interface Survey {
  linkUrl: string;
  id: string;
  surveyId: string;
  // imageUrl: string; // Ignore the value from highlighter-api and use a hard-coded image for now.
  name: string;
  status: SurveyStatus;
  type: SurveyType;
  deadline?: string; //? we should be able to remove the nullable from this, in a seperate PR
  isOverdue: boolean;
  isVisible: boolean;
  customData?: Record<string, string>
}

export interface ItemSurveyInstance extends Survey {
  type: SurveyType.Item;
}

export interface ProfileSurveyInstance extends Survey {
  type: SurveyType.Profile;
}

export interface DispatchedItemSurvey {
  id: string;
  boxItemId?: string;
  // !INFO: startedAt is coming in as seconds instead of miliseconds like a date getTime()
  startedAt?: number; // to check the date: new Date(startedAt * 1000)
  duration: number; // duration is time in seconds ex: 864000 = 10 days
  status: SurveyStatus;
  survey?: ItemSurvey;
  completedAt?: number;
}

export interface SurveyItem {
  id: string;
  description?: string;
  ingredients?: string;
  webUrl?: string;
}

// Info: This matches the surveystatus type in HighlighterDB
//! Not modifying the other SurveyStatus type because ItemSurveys and OptInSurveys might have different statuses.
// Currently, Overdue is a status only used for ItemSurveys.
export enum SurveyStatus {
  NotSent = 'not sent',
  Sent = 'sent',
  Completed = 'completed',
  Expired = 'expired',
  Cancelled = 'cancelled',
  Started = 'started',
  Overdue = 'overdue',
  Disqualified = 'disqualified',
  Declined = 'declined',
  PendingResponse = 'pending response',
}

export enum SurveyType {
  OneOff = 'oneOff',
  OptIn = 'optInSurvey',
  Item = 'itemSurvey',
  Profile = 'profileSurvey',
}
