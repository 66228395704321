import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Customer } from 'types/Customer';
import { ActiveSurveyInstance, ProfileSurveyInstance } from 'types/Offer';

import Survey from 'components/Survey';

interface PSProps {
  profileSurveyInstances: ProfileSurveyInstance[];
  customer: Customer;
}

export const ProfileSurveySection = (props: PSProps) => {
  const { profileSurveyInstances, customer } = props;
  const [activeSurveyInstance, setActiveSurveyInstance] =
    useState<ActiveSurveyInstance | null>(null);
  const [formModalVisible, setFormModalVisible] = useState(false);
  const uiTheme = useTheme();
  const smAndUpScreen = useMediaQuery(uiTheme.breakpoints.up('sm'));
  const useMobileStyles = !smAndUpScreen;

  const onClick = (psi: ProfileSurveyInstance) => {
    setActiveSurveyInstance({
      id: psi.id,
      surveyId: psi.surveyId,
      surveyType: psi.type,
      surveyUrl: psi.linkUrl,
    });
    setFormModalVisible(true);
  };

  return (
    <Box marginBottom={2}>
      <Typography variant={useMobileStyles ? 'h6' : 'h5'}>
        Enhanced Profile Questions
      </Typography>
      <Typography variant="body1" color="primary">
        We’ve created new surveys to enhance your profile! Your responses allow us to
        better match you with upcoming Studies in more product categories.{'\n'}
      </Typography>
      <Typography variant="body1" color="primary">
        If you want to make updates to your responses, please click the link to the survey
        below and resubmit.
      </Typography>

      {profileSurveyInstances.map((psi) => {
        return (
          <Link
            key={psi.id}
            to="#"
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              onClick(psi);
            }}
          >
            <Typography>{psi.name}</Typography>
          </Link>
        );
      })}

      {formModalVisible && activeSurveyInstance && (
        <Survey
          customer={customer}
          onClose={() => setFormModalVisible(false)}
          onStatusChange={(...args: any[]) => {
            // TODO handle status changed events at some point.
            // maybe we do not need this, since we will only be updating completed surveys?
            console.log('status changed', ...args);
          }}
          surveyInstance={activeSurveyInstance}
        />
      )}
    </Box>
  );
};
