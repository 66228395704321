import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useGetHighlighterAnswers, useSetHighlighterAnswers } from 'hooks/api/answers';
import { useGetHighlighterQuestions } from 'hooks/api/questions';
import React, { useEffect, useState } from 'react';
import { QuestionAnswers } from 'types/Answer';
import { Customer } from 'types/Customer';
import { HighlighterQuestion, PossibleAnswer } from 'types/Questions';

import { DemoQuestions } from 'components/DemoQuestions/DemoQuestions';
import { getIsDirtyValue, getUpdatedAnswers } from 'components/DemoQuestions/utils';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Spinner,
  Typography,
} from 'components/shared';

interface CompletedProfileDemoSectionProps {
  onSaveEligibilityChange: (id: string, saveEligibile: boolean) => void;
  customer: Customer;
}
const DemoQuestionsSection = (props: CompletedProfileDemoSectionProps): JSX.Element => {
  const uiTheme = useTheme();
  const smAndUpScreen = useMediaQuery(uiTheme.breakpoints.up('sm'));

  const { questions, isHlApiLoading: isGetQuestionsLoading } =
    useGetHighlighterQuestions();
  const {
    questionAnswers: originalQuestionAnswers,
    isHlApiLoading: isGetAnswersLoading,
  } = useGetHighlighterAnswers(props.customer.email);
  const { setHighlighterAnswers } = useSetHighlighterAnswers();

  const [isLoading, setIsLoading] = useState<{
    [questionId: string]: boolean | undefined;
  }>({});
  const [isDirty, setIsDirty] = useState<{ [questionId: string]: boolean | undefined }>(
    {}
  );
  const [currentQuestionAnswers, setCurrentQuestionAnswers] = useState<QuestionAnswers>({
    /*initialized via the use effect */
  });

  useEffect(() => {
    setCurrentQuestionAnswers({ ...originalQuestionAnswers });
  }, [isGetAnswersLoading]);

  const useMobileStyles = !smAndUpScreen;

  const _onSaveEligibilityChange = (id: string, saveEligibile: boolean): void => {
    props.onSaveEligibilityChange(id, saveEligibile);
  };

  const _onSelect = (
    question: HighlighterQuestion,
    answer: PossibleAnswer,
    possibleAnswers: PossibleAnswer[]
  ) => {
    if (originalQuestionAnswers == undefined) {
      return;
    }
    const updatedSelectedAnswers = getUpdatedAnswers(
      answer,
      currentQuestionAnswers[question.id] ?? {},
      possibleAnswers,
      question.allowMultiple
    );
    setCurrentQuestionAnswers({
      ...currentQuestionAnswers,
      [question.id]: updatedSelectedAnswers,
    });
    const isDirtyValue = getIsDirtyValue(
      updatedSelectedAnswers,
      originalQuestionAnswers[question.id]
    );
    setIsDirty({
      ...isDirty,
      [question.id]: isDirtyValue,
    });
    _onSaveEligibilityChange(question.id, isDirtyValue);
  };

  const handleSetHighlighterAnswers = async (
    answers: QuestionAnswers[string],
    questionId: string
  ) => {
    const questionAnswersTobeSaved: QuestionAnswers = {
      [questionId]: {
        ...answers,
      },
    };
    setIsLoading({ ...isLoading, [questionId]: true });
    await setHighlighterAnswers(questionAnswersTobeSaved);
    setIsLoading({ ...isLoading, [questionId]: false });
    setIsDirty({ ...isDirty, [questionId]: false });
    _onSaveEligibilityChange(questionId, false);
  };

  if (isGetAnswersLoading || isGetQuestionsLoading || questions == undefined) {
    return <Spinner variant="highlight" />;
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      style={{ marginBottom: 24, marginTop: 10 }}
    >
      <Grid item>
        {questions.length > 0 ? (
          questions.map((question: HighlighterQuestion) => (
            <Box marginBottom="24px" key={question.id}>
              <Box style={{ float: 'right', marginLeft: `16px` }}>
                <Button
                  onClick={() =>
                    handleSetHighlighterAnswers(
                      currentQuestionAnswers[question.id],
                      question.id
                    )
                  }
                  variant={'contained'}
                  color={'primary'}
                  disabled={!isDirty[question.id]}
                  endIcon={
                    isLoading[question.id] ? <CircularProgress size={20} /> : undefined
                  }
                  size={useMobileStyles ? 'small' : undefined}
                >
                  {isLoading[question.id] ? (useMobileStyles ? '' : 'Saving...') : 'Save'}
                </Button>
              </Box>
              <DemoQuestions
                question={question}
                onSelectAnswer={_onSelect}
                currentAnswers={currentQuestionAnswers[question.id]}
                onSave={handleSetHighlighterAnswers}
                onSaveEligibilityChange={_onSaveEligibilityChange}
                useMobileStyles
              />
            </Box>
          ))
        ) : (
          <Typography>{'We were unable to grab your questions'}</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default DemoQuestionsSection;
