import { useFeatureFlag } from 'configcat-react';
import { useGetSurveys } from 'hooks/api/surveys';
import { AuthenticatedUser } from 'types/Authentication';
import { Customer } from 'types/Customer';
import { SurveyStatus } from 'types/Offer';

import { Box, Divider, Spinner } from 'components/shared';

import DemoQuestionsSection from './DemoQuestions';
import { ProfileSurveySection } from './ProfileSurveysSection';

export interface PreferencesSectionProps {
  user: AuthenticatedUser;
  customer: Customer;
  onSaveEligibilityChange: (id: string, saveEligibile: boolean) => void;
}

export const PreferencesSection = (props: PreferencesSectionProps): JSX.Element => {
  const { onSaveEligibilityChange } = props;
  const { isHlApiLoading: isGetSurveysLoading, profileSurveyInstances } =
    useGetSurveys(true);

  const { value: isDisplayProfileSurveysEnabled, loading: isFlagLoading } =
    useFeatureFlag('isdisplayprofilesurveysenabled', false, {
      email: props.user.email,
      identifier: props.customer.id,
    });
  if (isGetSurveysLoading || isFlagLoading) {
    return <Spinner />;
  }
  const completedProfileSurveys = profileSurveyInstances.filter(
    (psi) => psi.status == SurveyStatus.Completed
  );
  return (
    <div style={{ maxWidth: 705, width: '100%' }}>
      <Box marginBottom="24px">
        {isDisplayProfileSurveysEnabled && (
          <ProfileSurveySection
            profileSurveyInstances={completedProfileSurveys}
            customer={props.customer}
          />
        )}
        <Divider />
        <DemoQuestionsSection
          onSaveEligibilityChange={onSaveEligibilityChange}
          customer={props.customer}
        />
      </Box>
    </div>
  );
};
