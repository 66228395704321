import type { ActiveSurveyInstance, ProfileSurveyInstance } from 'types/Offer';
import { SurveyStatus, SurveyType } from 'types/Offer';

import { useStyles } from 'components/offers/SurveyCard/SurveyCard.styles';
import { SurveyImage } from 'components/offers/SurveyImage';
import { Box, Button, Paper, Typography } from 'components/shared';

interface ProfileSurveyCardProps {
  onPressButton: (data: ActiveSurveyInstance) => void;
  profileSurveyInstance: ProfileSurveyInstance;
}

export const ProfileSurveyCard = (props: ProfileSurveyCardProps): JSX.Element => {
  const { onPressButton, profileSurveyInstance } = props;
  const { id, name, linkUrl, surveyId, status } = profileSurveyInstance;
  const classes = useStyles();

  const getBadgeText = () => {
    if (status != SurveyStatus.Completed) {
      return 'Complete \nASAP!';
    } else return 'Completed. Click to Update!'; // To be dealt with in CP-7721 what this actually says
  };

  const handlePressButton = (): void => {
    onPressButton({
      surveyUrl: linkUrl ?? '',
      id,
      surveyType: SurveyType.Profile,
      surveyId,
    });
  };

  return (
    <>
      <Paper elevation={3} className={classes.container}>
        <SurveyImage
          surveyType={SurveyType.Profile}
          alt={`Profile survey: ${name}`}
          onClick={handlePressButton}
          badge={{ text: getBadgeText() }}
        />

        <Typography title={name} variant="h6" className={classes.surveyName}>
          {`${name} Profile Survey`}
        </Typography>

        <Box className={classes.buttonWrapper}>
          <Button onClick={handlePressButton} variant="contained" color="primary">
            {"Let's get started"}
          </Button>
        </Box>
      </Paper>
    </>
  );
};
